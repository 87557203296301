<template>
  <div id="app" class="bg-green-300">
    <Silent sound="sounds/default.mp3"/>
    <div class="grid grid-cols-2 gap-0">
      <Howler v-for="item in howlerItems" :key="item.icon" :sound="item.sound" :icon="item.icon" :initialVolume="item.initialVolume" v-bind:initialSelected=item.initialSelected />
    </div>
    <PlayStop v-bind:howlerItems="howlerItems" />
    <Timer />
    <footer class="text-white text-center">
      05/2021, <a href="#" v-bind:class="shareLink" @click="shareLink">Share link</a>
    </footer>
  </div>
</template>

<script>
import Silent from './components/Silent.vue';
import Howler from './components/Howler.vue';
import PlayStop from './components/PlayStop.vue';
import Timer from './components/Timer.vue';

export default {
  name: 'App',
  components: {
    Silent,
    Howler,
    PlayStop,
    Timer
  },
  data () {
    return {
      howlerItems: [
        {sound: 'sounds/waves.mp3', icon: 'bi-tsunami', initialVolume: 1, initialSelected: true},
        {sound: 'sounds/old-ship.mp3', icon: 'bi-cart', initialVolume: 1, initialSelected: false},
        {sound: 'sounds/wind.mp3', icon: 'bi-wind', initialVolume: 0.6, initialSelected: false},
        {sound: 'sounds/rain-on-pavement.mp3', icon: 'bi-cloud-rain', initialVolume: 0.6, initialSelected: false},
        {sound: 'sounds/rainforest_ambience-GlorySunz-1938133500.mp3', icon: 'bi-tree', initialVolume: 0.5, initialSelected: false},
        {sound: 'sounds/forrest-bird.mp3', icon: 'bi-globe', initialVolume: 1, initialSelected: false},
        {sound: 'sounds/ventilator.mp3', icon: 'bi-arrow-repeat', initialVolume: 1, initialSelected: false},
        {sound: 'sounds/water.mp3', icon: 'bi-water', initialVolume: 1, initialSelected: false}
      ]
    }
  },
  methods: {
    shareLink() {
      if (navigator.share) {
        navigator.share({
          title: "https://sleepy.patland.ch",
          text: '',
          url: 'https://sleepy.patland.ch'
        }).then(() => {
        }).catch();
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}

</style>
