<template>
    <div class="silent">
        <!-- in order to support stopping the audio when unplugging the headphones we need to 
         work with a hidden audio element -->
        <audio class="hidden" controls loop mute id="silent" ref="silentAudio" v-on:pause="onPause">
            <source v-bind:src="publicPath + sound" type="audio/mp3">
            Your browser does not support the audio element.
        </audio>
    </div>
</template>
<script>
import {EventBus} from "../eventBus";

export default {
  name: 'Silent',
  props: {
    sound: String
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  created() {
      
  },
  methods: {
    onPause() {
      EventBus.$emit('STOP_ALL', {});
    }
  },
  mounted () {
    EventBus.$on('NOTIFY_SILENT', (isPlayingAll) => {
      this.$refs.silentAudio.volume = 0;
      if (isPlayingAll) {
        this.$refs.silentAudio.play();
      } else {
        this.$refs.silentAudio.pause();
      }
    });
    EventBus.$on('STOP_ALL', () => {
      this.$refs.silentAudio.pause();
    });
  }
}
</script>