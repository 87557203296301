<template>
  <div class="pt-4 pb-4">
    <i v-on:click="onPlayStop" class="icon" v-bind:class="[isPlaying ? 'stop' : 'play']"></i>
    <button v-on:click="onPlayStop" class="text-white font-bold py-2 px-4 rounded focus:outline-none" v-bind:class="[isPlaying ? 'stop' : 'play']">
      {{ setButtonText() }}
    </button>
  </div>
</template>

<script>
import {EventBus} from "../eventBus";

export default {
  name: "PlayStop",
  components: {
  },
  props: {
    howlerItems: Array
  },
  data () {
    return {
      isPlaying: false,
      loopsRunning: 0
    }
  },
  created () {
    // we need to know how many loops were active
    this.howlerItems.forEach(item => {
    if (localStorage.getItem(item.icon)) {
      try {
        var loopParameters = JSON.parse(localStorage.getItem(item.icon));
        if (loopParameters.isSelected) {
          this.loopsRunning += 1;
        }
      } catch (e) {
        // do nothing
      }
    }
    });
    this.loopsRunning = this.loopsRunning ? this.loopsRunning : 1;
  },
  mounted () {
      EventBus.$on('NOTIFY_APP', (isLoopPlaying) => {
        this.loopsRunning = isLoopPlaying ? this.loopsRunning + 1 : this.loopsRunning - 1;
        if (this.isPlaying) {
          if (this.loopsRunning && this.loopsRunning <= this.howlerItems.length) {
            this.isPlaying = true;
          } else {
            this.isPlaying = false;
          }
          EventBus.$emit('NOTIFY_LOOPS', this.isPlaying);     
          EventBus.$emit('NOTIFY_SILENT', this.isPlaying);
        }
      });
      EventBus.$on('STOP_ALL', () => {
        this.isPlaying = false;
      });
      EventBus.$on('STOP_ALL', () => {
        this.isPlaying = false;
      });
      EventBus.$on('NOTIFY_PLAY_STOP', (isPlaying) => {
        this.isPlaying = isPlaying;
      });
  },
  methods: {
    onPlayStop() {
      this.isPlaying = !this.isPlaying;
      EventBus.$emit('NOTIFY_LOOPS', this.isPlaying);
      EventBus.$emit('NOTIFY_SILENT', this.isPlaying);
    },
    setButtonText() {
      return this.isPlaying ? 'Stop' : 'Play';
    }
  }
}
</script>
<style>
.play {
  @apply bg-blue-500 hover:bg-blue-700;
}
.stop {
  @apply bg-red-500 hover:bg-red-700;
}
</style>
