<template>
  <div>
    <div>
      <i v-on:click="onPlay" class="icon " v-bind:class="[isSelected ? 'enabled' : 'disabled', icon]"></i>
    </div>
    <div>
      <input type="range" min="0" max="1" step="0.1" class="slider" id="howlerRange" v-model="volume" @change="onVolume">
    </div>
    <div class="error">{{ error }}</div>
  </div>
</template>
<script>
import {EventBus} from "../eventBus";
import {Howl} from 'howler';

export default {
  name: 'Howler',
  components: {
  },
  props: {
    sound: String,
    icon: String,
    initialVolume: Number,
    initialSelected: Boolean
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      howlPlayer: undefined,
      error: '',
      isSelected: this.initialSelected,
      volume: this.initialVolume,
      loopParameters: {}
    }
  },
  created() {
  },
  methods: {
    loadSound() {
      this.howlPlayer = new Howl({
        src: [this.publicPath + this.sound],
        loop: true,
        volume: this.volume
      });
    },
    onVolume() {
      this.howlPlayer.volume(this.volume);
      this.writeLoopParameters();
    },
    onPlay() {
      this.isSelected = !this.isSelected;
      if (!this.isSelected) {
        this.howlPlayer.stop();
      }
      this.writeLoopParameters();
      EventBus.$emit('NOTIFY_APP', this.isSelected);
    },
    readLoopParameters() {
      if (localStorage.getItem(this.icon)) {
        try {
          this.loopParameters = JSON.parse(localStorage.getItem(this.icon));
          this.volume = this.loopParameters.volume;
          this.isSelected = this.loopParameters.isSelected;
        } catch(e) {
          localStorage.removeItem(this.icon);
        }
      } else {
        this.writeLoopParameters();
      }
    },
    writeLoopParameters() {
        this.loopParameters.isSelected = this.isSelected;
        this.loopParameters.volume = this.volume;
        localStorage.setItem(this.icon, JSON.stringify(this.loopParameters));
    }
  },
  mounted () {
    EventBus.$on('NOTIFY_LOOPS', (doPlay) => {
      if (doPlay && this.isSelected) {
        this.howlPlayer.volume(this.volume);
        this.howlPlayer.play();
      } else {
        this.howlPlayer.stop();
      }
    });
    EventBus.$on('STOP_ALL', () => {
      this.howlPlayer.fade(this.volume, 0, 2000);
    });
    this.readLoopParameters();
    this.loadSound();
  }
}
</script>
<style>
.disabled {
  color: white;
  width: 200px;
  height: 200px;
  font-size: 4rem;
}
.enabled {
  color: green;
  width: 200px;
  height: 200px;
  font-size: 4rem;
}
</style>
