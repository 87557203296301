<template>
  <div class="pb-1">
    <div>
      <span class="text-white text-3xl" v-text="currentDurationText"></span>
    </div>
    <div class="pt-4 pb-4">
      <input class="p-0" type="range" min="0" max="1800" step="10" v-model="duration" orient="vertical" />
    </div>
    <div>
      <button v-on:click="onCountdown" class="text-white font-bold py-2 px-4 rounded focus:outline-none" v-bind:class="[isCountdownRunning ? 'stop' : 'start']">
        {{ setButtonText() }}
      </button>
    </div>
    <div>
      <button v-if="!isCountdownRunning" v-on:click="onResetCountdown" class="text-white py-2 px-4 rounded focus:outline-none hover:text-red-700">
        Reset timer
      </button>
    </div>
  </div>
</template>

<script>
import {EventBus} from "../eventBus";
import dayjs from 'dayjs';

var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

export default {
  name: "Timer",
  components: {
  },
  props: {

  },
  data () {
    return {
      isCountdownRunning: false,
      duration: 0,
      currentDuration: 0,
      timer: undefined
    }
  },
  created () {

  },
  methods: {
    onCountdown() {
      if (this.isCountdownRunning) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.isCountdownRunning = !this.isCountdownRunning;
      EventBus.$emit('NOTIFY_PLAY_STOP', this.isCountdownRunning);
      EventBus.$emit('NOTIFY_LOOPS', this.isCountdownRunning);
      EventBus.$emit('NOTIFY_SILENT', this.isCountdownRunning);
      if (this.isCountdownRunning) {
        if (this.currentDuration == 0) {
          this.currentDuration = this.duration;
        }
        this.timer = setInterval( () => {
          if (this.currentDuration > 0) {
            this.currentDuration--;
          } else {
            this.isCountdownRunning = false;
            clearInterval(this.timer);
            this.timer = null;
            EventBus.$emit('STOP_ALL');
          }
        }, 1000);
      }
    },
    onResetCountdown() {
      this.currentDuration = this.duration;
    },
    setButtonText() {
      return this.isCountdownRunning ? 'Stop timer' : 'Start timer';
    },
    getDuration() {
      return this.isCountdownRunning ? this.currentDurationText : this.durationText;
    }
  },
  computed: {
    durationText: function() {
      var unit = this.duration < 60 ? ' secs' : ' mins';
      return dayjs.duration(this.duration * 1000).format('mm:ss') + unit;
    },
    currentDurationText: function() {
      var unit = this.duration < 60 ? ' secs' : ' mins';
      return dayjs.duration(this.currentDuration * 1000).format('mm:ss') + unit;
    }
  },
  watch: {
    duration (newDuration) {
      localStorage.duration = newDuration;
      this.currentDuration = newDuration;
    }
  },
  mounted () {
    if (localStorage.duration) {
      this.duration = localStorage.duration;
    }
  }  
}
</script>
<style>
.start {
  @apply bg-blue-500 hover:bg-blue-700;
}
.stop {
  @apply bg-red-500 hover:bg-red-700;
}
</style>
